import {
  WalletIcon,
  DashboardIcon,
  DashboardBarIcon,
  HistoryBarIcon,
  ActivityLogBarIcon,
  SettingsBarIcon,
  UserManagementBarIcon,
  CurrencyTabIcon,
  CoinBarIcon,
  ReportsIcon,
  CryptoWithdrawTabIcon,
  APIKeysIcon,
} from "../../../icons";
import { AdminRoutes, GenericConstant, Routes } from "../../../utils/Constants";

const Users = {
  mainTitle: "User Management",
  title: "Users",
  classsChange: "mm-collapse",
  to: AdminRoutes.USERS,
  roles: [
    GenericConstant._SUPER_ADMIN,
    GenericConstant._SUPERVISOR,
    GenericConstant._ADMIN,
  ],
};

const Admins = {
  mainTitle: "User Management",
  title: "Admins",
  classsChange: "mm-collapse",
  to: AdminRoutes.ADMINS,
  roles: [GenericConstant._SUPER_ADMIN],
};

const TransactionHistory = {
  mainTitle: "Transaction History",
  title: "Transaction History",
  classsChange: "mm-collapse",
  to: AdminRoutes.TRANSACTION_HISTORY,
  roles: [GenericConstant._SUPER_ADMIN, GenericConstant._SUPERVISOR],
};

const TransferBalanceHistory = {
  mainTitle: "Transaction History",
  title: "Transfer Balance History",
  classsChange: "mm-collapse",
  to: AdminRoutes.TRANSFER_BALANCE_TRANSACTION,
  roles: [GenericConstant._SUPER_ADMIN, GenericConstant._ADMIN],
};

const UserMenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: (
      <DashboardBarIcon className="w-100 h-100" style={{ padding: "1px" }} />
    ),
    to: "dashboard",
  },
  //Accounts
  {
    title: "Accounts",
    classsChange: "mm-collapse",
    iconStyle: <WalletIcon className="w-100 h-100" />,
    to: "accounts",
  },
  // Transaction History
  {
    title: "Transaction History",
    subTitle: "Transaction History",
    classsChange: "mm-collapse",
    iconStyle: <HistoryBarIcon />,
    to: Routes.TRANSACTION_HISTORY,
    content: [
      {
        mainTitle: "Transaction History",
        title: "Transaction History",
        classsChange: "mm-collapse",
        to: Routes.TRANSACTION_HISTORY,
      },
      {
        mainTitle: "Transaction History",
        title: "Transfer Balance History",
        classsChange: "mm-collapse",
        to: Routes.TRANSFER_BALANCE_HISTORY,
      },
    ],
  },
  //Settings
  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <SettingsBarIcon />,
    to: "settings",
  },
  //Activity
  {
    title: "Activity",
    classsChange: "mm-collapse",
    iconStyle: <ActivityLogBarIcon />,
    to: "activity",
  },
];

const SidebarMenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <DashboardIcon />,
    to: "/admin/dashboard",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },

  {
    title: "Fiat Management",
    classsChange: "mm-collapse",
    iconStyle: <WalletIcon />,
    to: "/admin/fiat-management",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },

  {
    title: "Transaction History",
    subTitle: "Transaction History",
    classsChange: "mm-collapse",
    iconStyle: <HistoryBarIcon />,
    to: "/admin/transaction-history",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
    content: [TransactionHistory, TransferBalanceHistory],
  },

  {
    title: "User Management",
    subTitle: "Users",
    classsChange: "mm-collapse",
    iconStyle: <UserManagementBarIcon />,
    to: "/admin/users",
    content: [Users, Admins],
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Coin Management",
    classsChange: "mm-collapse",
    iconStyle: <CoinBarIcon className="w-100 h-100" />,
    to: "/admin/coin-management",
    roles: [GenericConstant._SUPER_ADMIN],
  },
  {
    title: "Crypto Withdraw",
    classsChange: "mm-collapse",
    iconStyle: <CryptoWithdrawTabIcon className="w-100 h-100" />,
    to: "/admin/crypto-withdraw",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Currencies",
    classsChange: "mm-collapse",
    iconStyle: <CurrencyTabIcon className="w-100 h-100" />,
    to: "currencies",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Activity",
    classsChange: "mm-collapse",
    iconStyle: <ActivityLogBarIcon />,
    to: "/admin/activity",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Organisation",
    classsChange: "mm-collapse",
    iconStyle: <APIKeysIcon />,
    to: "/admin/organisation",
    roles: [GenericConstant._SUPER_ADMIN, GenericConstant._ADMIN],
  },

  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <SettingsBarIcon />,
    to: "/admin/settings",
    roles: [
      GenericConstant._SUPER_ADMIN,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Reports",
    classsChange: "mm-collapse",
    iconStyle: <ReportsIcon />,
    to: "/admin/reports",
    roles: [GenericConstant._SUPER_ADMIN],
  },
];

const getRoleTabs = (role = "N/A") => {
  return SidebarMenuList.reduce((list, tab) => {
    if (tab?.roles?.includes(role) && tab?.content && tab.content.length > 0) {
      let content = tab.content.filter((t) => t?.roles?.includes(role));
      const subtab = content[0];
      if (subtab) {
        tab = {
          ...tab,
          to: subtab.to,
          title: subtab.title,
          subTitle: subtab.title,
        };
      }
      list.push({ ...tab, content });
    } else if (tab.roles.includes(role)) {
      list.push(tab);
    }
    return list;
  }, []);
};
export const MenuList = {
  user: UserMenuList,
  superAdmin: getRoleTabs(GenericConstant._SUPER_ADMIN),
  supervisor: getRoleTabs(GenericConstant._SUPERVISOR),
  admin: getRoleTabs(GenericConstant._ADMIN),
};
